import React, { Component } from "react";
import Image from "../../assets/img/player.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Project extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.state = { show: false, videoUrl: "", projects:[] };
  }

  async componentDidMount() {
    
    // Simple GET request using fetch
    const response = await fetch('https://cpanel.fi-erme.com/api/projets/');
    const json = await response.json();
    const sortedData = json.data.sort((a, b) => a.attributes.Numero_de_projet - b.attributes.Numero_de_projet);
    this.setState({ projects: sortedData });
}

  handleClose(event) {
    this.setState({ show: false });
  }
  handleShow(event) {
    console.log();
    const loadedVideoUrl = event.target.attributes.url.value;
    this.setState({ show: true, videoUrl: loadedVideoUrl });
  }
  render() {
    return (
      <React.Fragment>
        <h3 style={{ color: "#ffffff" }} className="text-center mb-3 mt-3">
          Les Projets de PROMO 3
        </h3>
        <div className="row row-cols-1 row-cols-md-3 mt-3">
        {this.state.projects && this.state.projects.map((project)=>(
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url={project.attributes.URL_Video}
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet {project.attributes.Numero_de_projet}</h5>
              <div className="card-body text-center">
               {project.attributes.Titre}
              </div>
            </div>
          </div>
        ))}
        </div>
        <h3 style={{ color: "#ffffff" }} className="text-center mb-3 mt-3">
          Les Projets de PROMO 1
        </h3>
        <div className="row row-cols-1 row-cols-md-3 mt-3">
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/gMRcR4MHOJw"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 1</h5>
              <div className="card-body text-center">
                VARIASTEL-C Banc d'étude de la variation de vitesse des moteurs
                asynchrones triphasés
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/OackJjDfjDI"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 2</h5>
              <div className="card-body text-center">
                Module correcteur PID : PED020300
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/5z7lrmMgWNg"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 3</h5>
              <div className="card-body text-center">
                Jeu d'équipement TP 1131 : Platine compacte
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/kOEFTJPd4D0"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 4</h5>
              <div className="card-body text-center">
                Hacheurs/Onduleurs Monophasés/Triphasés Didactisé TBTS 150/300 W
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/GfYCiwgHwqg"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 5</h5>
              <div className="card-body text-center">
                Module d'étude d'un hacheur 2 quadrants 30 V 5A PED020200 ou
                équivalent
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/RNL9_6IK72k"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 6</h5>
              <div className="card-body text-center">
                Gradateur Monophasé Triphasé TBTS 150/300W
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/EehWJ3ScT_s"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 7</h5>
              <div className="card-body text-center">
                BICMAC-S, Banc Instrumenté de Charge Machines Alternatives &
                Continues
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/ZXmZeLTo_SM"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 8</h5>
              <div className="card-body text-center">
                Banc pédagogique d'étude des véhicules électriques et hybrides
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/t5uLcJXFnAk"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 9</h5>
              <div className="card-body text-center">
                Banc d'étude des tableaux de bord et accessoires de
                signalisation
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 10</h5>
              <div className="card-body text-center">
                Kit solaire autoconsommation de 3kWc Triphasé
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/AVg9xq8UGMQ"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 11</h5>
              <div className="card-body text-center">Robot mobile</div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/FE44bHDI-M0"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 12</h5>
              <div className="card-body text-center">Robot Humanoïde</div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/ayRJpcU3CsM"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 13</h5>
              <div className="card-body text-center">
                Onduleur triphasé avec carte de contrôle du type TMDXHVMTRKIT5X
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/9m1uOSh5ps0"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 14</h5>
              <div className="card-body text-center">
                Plateforme de developpement pour onduleur solaire
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/GxGzsg6yiNE"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 15</h5>
              <div className="card-body text-center">
                Kit complet éolienne du type EOLIE500
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/QLU3hJ7X7hY"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 16</h5>
              <div className="card-body text-center">
                Banc de charge BICSIN-S ELS320000
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/TokrFEJk8XY"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 17</h5>
              <div className="card-body text-center">
                Capacimètre LCR de mesure d'impédance du type LCR 8001G
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <img
                class="card-img-top"
                onClick={this.handleShow}
                url="https://www.youtube.com/embed/2uOF97gjquY"
                src={Image}
                alt="Card image cap"
              ></img>
              <h5 className="card-header text-center">Projet 18</h5>
              <div className="card-body text-center">
                Analyse des défauts de l’installation électrique de la FSTG via
                la Caméra de type CA 1850 Chauvin Arnoux
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleClose}
          animation={true}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Projet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="500"
              src={this.state.videoUrl}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Project;
